<template>
    <div class="tree-com">
        <Optional
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :checkAll="checkAll"
            :dropDownSearchType="dropDownSearchType"
            :treeShowList="treeShowList"
            :breadcrumbsList="breadcrumbsList"
            :optionalLoadTitle="optionalLoadTitle"
            :searchDropdownData="searchDropdownData"
            :searchOptionalLoadTitle="searchOptionalLoadTitle"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick="handlerAllTreeNodeClick"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @treeNodeNextLevelClick="treeNodeNextLevelClick"
            @changeTreeListLevel="changeTreeListLevel"
        />
        <Selected :selectTreeNodeData="selectTreeNodeData" @handlerDelTreeNode="handlerDelTreeNode"/>
    </div>
</template>
<script>
import Optional from "../components/Optional/index.vue";
import Selected from "../components/Selected/index.vue";
export default {
    name: "TreeCom",
    components: {
        Optional,
        Selected,
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList: Array,
        selectTreeNodeData: Array,
        breadcrumbsList: Array,
        optionalLoadTitle: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    methods: {
        selectFocus () {
            this.$emit('selectFocus')
        },
        selectInput (inputVal) {
            this.$emit('selectInput', inputVal)
        },
        handlerAllTreeNodeClick (data) {
            this.$emit("handlerAllTreeNodeClick", data)
        },
        handlerTreeNodeClick(data) {
            this.$emit("handlerTreeNodeClick", data);
        },
        treeNodeNextLevelClick (data) {
            this.$emit("treeNodeNextLevelClick", data);
        },
        handlerDelTreeNode (data) {
            this.$emit("handlerDelTreeNode", data);
        },
        changeTreeListLevel (data) {
            this.$emit("changeTreeListLevel", data)
        }
    },
};
</script>
<style lang="scss" scoped>
.tree-com {
    display: flex;
    justify-content: center;
}
</style>
